
//ts overrides
/* eslint-disable @typescript-eslint/no-use-before-define */

//import base functions
import { useRef, useEffect, useState, SetStateAction } from 'react';
//import mui design items
import { Avatar, Box, Button, Dialog, Grid, IconButton, Paper, Typography } from "@mui/material";
//import routing
import { useParams } from 'react-router-dom';
//import data
import DB from '../../config/data.json';
import '../../custom/custom.css';
//import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
//import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
//import modelViewer
import '@google/model-viewer';
import CloseIcon from '@mui/icons-material/Close';
import { QRCodeSVG } from 'qrcode.react';
import { Stack } from '@mui/system';

//create new react element for model-viewer js
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'model-viewer': MyViewer;
        }
        interface MyViewer {
            children: Element[];
            id: string;
            ref: any;
            style: any;
            src: string;
            ar: true;
            "ar-modes": string;
            "camera-controls": true;
            poster: string;
            "shadow-intensity": string;
        }
    }
}

//define type Account
type Account = {
    id: number;
    name: string;
    s3path: string;
    guid: string;
    imageHost: string;
}

//define type Variant
type Variant = {
    id: string;
    modelSrc: string;
    poster: string;
}

const ModelView = () => {
    const [open, setOpen] = useState(false);
    //ref -- custom modelviewer implement
    const ref = useRef<any>({});
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    //route params
    const remoteParams = useParams<any>();
    //active model
    const [variant, setVariant] = useState<Variant>({} as Variant);
    //route variants
    const [variants, setVariants] = useState<Variant[]>([]);
    //loader
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 1000);
    }, []);
    useEffect(() => {
        function handleResize() {
            console.log(getWindowDimensions())
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    useEffect(() => {
        const loadVariants = (account: Account) => {
            //get all detail params(ids and posters)
            const innerParams = remoteParams["*"];
            //get first index (because posters maybe contains slashes)
            const firstIndex = innerParams?.indexOf('/') as number;
            //get ids from route
            const ids = innerParams?.slice(0, firstIndex) as string;
            //get posters from route
            const posters = innerParams?.slice(firstIndex + 1) as string;
            //get base url for models and posters
            const fileHost = account.imageHost as string
            //get route path for models and posters
            const s3Path = account.s3path as string
            //get id list from ids
            const idList = ids?.split(',') as string[];
            //get id list from posters
            const posterList = posters?.split(',') as string[];
            //zip two arrays into Variant[] model
            var newVariants = idList.map(function (id, i) {
                //build model source
                const modelSrc = fileHost + 'models/' + s3Path + id + '.glb';
                //set Variant model
                return { id, poster: posterList[i], modelSrc } as Variant;
            });
            //set variants to state
            setVariants(newVariants);
            //set initial variant to state
            setVariant(newVariants[0]);
            //open
            // setLoading(false);
        };
        //get accountId
        const guid = remoteParams.accountId;
        //check account: if exist
        if (DB.accounts.some(e => e.guid === guid)) {
            const account = DB.accounts.find(e => e.guid === guid) as Account;
            loadVariants(account);
        }
        else { // unknown account will be restricted
            return;
        }
        //refresh flag - only re-run the effect if remoteparams changes
    }, [remoteParams]);

    //it will be override loaded variant -- 
    const changeModel = (item: Variant) => {
        setVariant(item);
    };

    const isTouchableDevice = () =>{
        try {
            const userAgent = navigator.userAgent.toLowerCase();

            const isMobile = /iPhone|Android/i.test(navigator.userAgent);
            const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
            const isIpad = /macintosh/.test(userAgent) && 'ontouchend' in document;
            if(!isMobile && !isTablet && !isIpad){
                return false;
            }
            return true;

        } catch (e) {
            console.error('(Touch detect failed)', e);
            return false;
        }
    }
    const handleOnShow = () => {
        if (isTouchableDevice()) {
            ref.current.activateAR();
        }
        else {
            setOpen(true);
        }

    };
    return (
        <>
            {loading &&
                <img alt="ar-loader" id="qrimage" src="/old/assets/lunar-loader.gif" />
            }
            {!loading &&
                // <Grid container spacing={2}>
                <Box height={windowDimensions.height} width={'100%'}>
                    <Box height={'75%'}>
                        <model-viewer
                            id='#container'
                            ref={ref}
                            auto-rotate
                            touch-action="none"
                            camera-orbit="-33deg 80deg m" quick-look-browsers="safari chrome" shadow-intensity="1.3"
                            environment-image="neutral" data-js-focus-visible="" ar-status="not-presenting"
                            style={{ width: '100%', height: '100%' }}
                            src={variant.modelSrc} ar ar-modes="scene-viewer webxr quick-look" camera-controls poster={variant.poster}>
                            <div className="progress-bar hide" slot="progress-bar">
                                <div className="update-bar"></div>
                            </div>
                            <div slot="ar-button">
                            </div>
                        </model-viewer>
                    </Box>
                    <Box marginX={1} alignContent='center'>
                        {variants.length > 1 &&
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={10}
                                slidesPerView={variants.length > 3 ? 3 : variants.length}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true, hide: true }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                            >
                                {variants.map((item) => (
                                    <SwiperSlide key={item.id}>
                                        <Paper elevation={3} key={item.id} style={{ height: '100%', alignItems: "center", justifyContent: "center", display: 'flex' }} sx={{ border: item.id === variant.id ? 3 : 0, borderColor: '#FD1844' }}>
                                            <img
                                                style={{ maxWidth: '100%', height: '90px', padding: 0, margin: 0, alignItems: "center", justifyContent: "center", objectFit: 'cover' }}
                                                src={`${item.poster}`}
                                                srcSet={`${item.poster}`}
                                                alt={item.id}
                                                loading="lazy"
                                                onClick={() => changeModel(item)}
                                            />
                                        </Paper>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        }
                        <Box width='100%' style={{ height: '100%', alignItems: "center", justifyContent: "center", display: 'flex', marginTop: 10, }}>
                            <Button style={{ backgroundColor: '#FD1844', padding: 20 }} onClick={handleOnShow}>
                                <img alt='' src="/old/assets/aricon108.png" height={30}>
                                </img>
                                <Typography marginLeft={2} color={'white'} > Evinde Gör</Typography>
                            </Button>
                        </Box>

                    </Box>
                    <Dialog
                        fullScreen
                        open={open}
                    >
                        <Button sx={{ display: { lg: 'flex', xs: 'none' } }} style={{ backgroundColor: '#FD1844', padding: 20 }} onClick={() => setOpen(false)}>
                            <IconButton
                                edge="end"
                                color="default"
                                size='large'
                                onClick={() => setOpen(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography marginLeft={2} color={'white'} > Kapat</Typography>
                        </Button>

                        <Box height={'100%'}>
                            <Stack height={'100%'} style={{ alignItems: "center", justifyContent: "center", display: 'flex' }}>
                                <QRCodeSVG size={256} value={window.location.href} />
                                <Typography variant="h5" padding={10}>"Artırılmış Gerçeklik" ile ürünü evinizde görüntülemek
                                    için mobil cihazınıza geçiş yapın.
                                </Typography>
                            </Stack>
                        </Box>

                    </Dialog>
                </Box >
            }
        </>
    );
};

export default ModelView;
