import Detail from "../screens/Detail";
import Home from "../screens/Home";
import Login from "../screens/Login";

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: "/render/:accountId/*",
    component: Detail,
    name: "Render Detail",
    protected: false,
  },
];

export default routes;
